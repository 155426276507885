<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <a
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#createNewUserModal"
          @click.prevent="handleModalClosed"
        >
          Agregar Usuario
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
      </div>
      <div class="d-flex align-items-center">
        <form
          class="search-box position-relative me-15"
          @submit.prevent="filterResults"
        >
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            v-model="searchQuery"
            placeholder="Buscar Usuarios"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                USER NAME
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                EMAIL
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOMBRE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                APELLIDOS
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                CIUDAD
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ROL
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in paginatedItems" :key="index">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16 long-text"
                  >
                    {{ user.username }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary long-text">
                {{ user.email }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-black-emphasis long-text"
              >
                {{ user.firstName }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted long-text">
                {{ user.lastName }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted long-text">
                {{ user.city?.name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted long-text">
                {{
                  user?.groups?.length > 0
                    ? user.groups[0].name
                    : user?.compressed?.groups?.length > 0
                    ? user?.compressed?.groups[0].name
                    : ""
                }}
              </td>
              <td class="action-link">
                <router-link class="custom-link" :to="`/profile/${user.id}`">
                  <i
                    class="ph ph-eye me-2 action-link"
                    style="font-size: 24px"
                  />
                </router-link>
                <i
                  class="ph ph-pencil action-link cursor-pointer"
                  style="font-size: 24px"
                  data-bs-toggle="modal"
                  data-bs-target="#createNewUserModal"
                  @click="openModal(user)"
                />
                <i
                  class="ph ph-trash ms-2 action-link cursor-pointer"
                  style="font-size: 24px"
                  @click="deleteUser(user.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="pagination-area d-md-flex mb-25 justify-content-between align-items-center"
    >
      <p class="ms-20 mb-0 text-paragraph">
        Mostrando <span class="fw-bold">{{ paginatedItems?.length }}</span> de
        <span class="fw-bold">{{ items?.length }}</span> Resultados
      </p>
      <nav class="mt-15 mt-md-0">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              @click.prevent="goToPreviousPage"
              aria-label="Previous"
            >
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click.prevent="goToNextPage"
              aria-label="Next"
            >
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <CreateNewUserModal
    :axios="axios"
    :id="selectedItem?.id"
    :no-close-on-backdrop="true"
    :title="title"
    @modal-closed="handleModalClosed"
  />
</template>

<script>
import { computed } from "vue";
import CreateNewUserModal from "./CreateNewUserModal.vue";
import * as StateStore from "@/utils/store";
import Swal from "sweetalert2";

export default {
  name: "UsersList",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  components: {
    CreateNewUserModal,
  },
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() =>
        this.selectedItem === null ? "Crear Nuevo " : "Editar "
      ),
    };
  },
  computed: {
    filteredAnswers() {
      if (this.items && Array.isArray(this.items)) {
        return this.items.filter((item) => {
          return (
            item.username
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            item.email
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            item.firstName
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            item.lastName
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            item.city?.name
              ?.toLowerCase()
              .includes(this.searchQuery?.toLowerCase())
          );
        });
      }
      return [];
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredAnswers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAnswers?.length / this.itemsPerPage);
    },
  },
  methods: {
    filterResults() {
      // Este método se llama al enviar el formulario, pero no es necesario hacer nada aquí
    },
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);
    },
    openModal(item) {
      this.selectedItem = item;
      // console.log("selectedItem: ", this.selectedItem);
    },
    async deleteUser(id) {
      const result = await Swal.fire({
        title: "¿Está seguro de eliminar este registro?",
        text: "No podrá revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });
      if (result.isConfirmed) {
        try {
          await StateStore.actions.actionsDeleteUser({
            axios: this.axios,
            id,
          });
          await this.getAllUser(); // Recarga los datos después de la eliminación.

          // Mostrar mensaje de éxito
          Swal.fire({
            title: "Eliminado",
            text: "El usuario ha sido eliminado correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } catch (error) {
          console.error("Error al eliminar el usuario:", error);
          Swal.fire({
            title: "Error",
            text: "Hubo un problema al eliminar el usuario.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllUser() {
      await StateStore.actions.actionsGetAllUsersList({ axios: this.axios });
      this.items = StateStore.getters.getUsersList();
    },
  },
  created() {
    this.getAllUser();
  },
};
// export default defineComponent({
//   name: "UsersList",
//   props: {
//     axios: {
//       type: [Object, Function],
//       required: true
//     }
//   },
//   components: {
//     CreateNewUserModal,
//   },
//   setup(props) {
//     const selectedItem = ref(null);
//     const title = computed(() => selectedItem.value === null ? 'Crear Nuevo ' : 'Editar ');
//     const users = ref([] as any[]);
//
//     return {
//       loading: ref(false),
//       alert: ref(false),
//       users,
//       selectedItem,
//       title,
//       handleModalClosed() {
//         selectedItem.value = null;
//         // console.log("selectedItem.value: ", selectedItem.value);
//
//       },
//       openModal(item) {
//         selectedItem.value = item;
//         // console.log("selectedItem.value: ", selectedItem.value);
//
//       },
//       async deleteUser(id: number) {
//         if (confirm("¿Está seguro de que desea eliminar el usuario?")) {
//           // console.log('El usuario confirmó la eliminación');
//           await StateStore.actions.actionsDeleteUser({'axios': props.axios, 'id': id});
//           location.reload();
//         } else {
//           // console.log('El usuario canceló la eliminación');
//         }
//       },
//       async getAllUsers() {
//         await StateStore.actions.actionsGetAllUsersList({'axios': props.axios});
//         users.value = StateStore.getters.getUsersList();
//       }
//     };
//   },
//   created() {
//     this.getAllUsers();
//   },
// });
</script>
<style>
.custom-link {
  text-decoration: none;
}

.long-text {
  white-space: pre-wrap;
  /* Permite que el texto se divida en varias líneas */
  word-break: break-word;
  /* Rompe las palabras largas */
}

.cursor-pointer {
  cursor: pointer;
}
</style>
