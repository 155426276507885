<template>
  <div
    ref="mymodal"
    class="modal fade createNewModal"
    id="createNewUserModal"
    tabindex="-1"
    aria-hidden="true"
    @click.self="handleBackdropClick"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Usuario</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25',
                    { 'has-error': !item.firstName && showError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10"> Nombre </label>
                  <input
                    v-model="item.firstName"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Johny"
                    @input="
                      checkLength('firstName', 15);
                      checkMinLength('firstName', 1);
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25',
                    { 'has-error': !item.lastName && showError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10"> Apellido </label>
                  <input
                    v-model="item.lastName"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Vino"
                    @input="
                      checkLength('lastName', 30);
                      checkMinLength('lastName', 1);
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25',
                    { 'has-error': !item.username && showError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10">
                    Nombre del Usuario
                  </label>
                  <input
                    v-model="item.username"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. jvino"
                    @input="
                      checkLength('username', 10);
                      checkMinLength('username', 1);
                    "
                  />
                </div>
              </div>
              <div v-if="currentUser?.id !== item?.id" class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25',
                    { 'has-error': !item?.groups && showError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10"> Rol </label>
                  <select
                    v-model="item.groups"
                    class="form-control shadow-none rounded-0 text-black"
                  >
                    <option
                      v-for="(groupOption, index) in groups"
                      :value="groupOption.id"
                      :key="index"
                    >
                      {{ groupOption.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25',
                    { 'has-error': !item.email && showError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10"> Email </label>
                  <input
                    v-model="item.email"
                    type="email"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. johny12345@gmail.com"
                  />
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.phone && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Teléfono
                  </label>
                  <input v-model="item.phone" type="number" class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. 000 4356 654"
                    @input="checkLength('phone', 11); checkMinLength('phone', minLen)" />
                </div>
              </div> -->
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-15 mb-md-25">
                  <label class="text-black fw-semibold mb-10"> Ciudad </label>
                  <select
                    v-model="item.city"
                    class="form-control shadow-none rounded-0 text-black"
                  >
                    <option
                      v-for="(cityOption, index) in cities"
                      :value="cityOption.id"
                      :key="index"
                    >
                      {{ cityOption.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!id" class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25 position-relative',
                    { 'has-error': !item.password2 && confirmPasswordError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10">
                    Contraseña
                  </label>
                  <div class="password-input">
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      class="form-control shadow-none rounded-0 text-black pr-40"
                      placeholder="Introduce una contraseña segura"
                      @input="
                        checkLength('password', maxLen * 2);
                        checkMinLength('password', minLen);
                      "
                      style="padding-right: 2.5rem"
                    />
                    <button
                      class="toggle-visibility"
                      type="button"
                      @click="togglePasswordVisibility"
                    >
                      <img
                        class="input-right-icon"
                        :src="
                          showPassword
                            ? 'https://cdn-icons-png.flaticon.com/128/10812/10812267.png'
                            : 'https://cdn-icons-png.flaticon.com/128/159/159604.png'
                        "
                        alt="Toggle Password Visibility"
                        width="25"
                        height="25"
                        style="filter: invert(0.4)"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="!id" class="col-lg-6 col-md-6">
                <div
                  :class="[
                    'form-group mb-15 mb-md-25 position-relative',
                    { 'has-error': !item.password2 && confirmPasswordError },
                  ]"
                >
                  <label class="text-black fw-semibold mb-10">
                    Confirmar Contraseña
                  </label>
                  <div class="password-input">
                    <input
                      :type="showConfirmPassword ? 'text' : 'password'"
                      v-model="password2"
                      class="form-control shadow-none rounded-0 text-black pr-40"
                      placeholder="Confirme su contraseña"
                      @input="
                        checkLength('password', maxLen * 2);
                        checkMinLength('password', minLen);
                        validatePassword();
                      "
                      style="padding-right: 2.5rem"
                    />
                    <button
                      class="toggle-visibility"
                      type="button"
                      @click="toggleConfirmPasswordVisibility"
                    >
                      <img
                        class="input-right-icon"
                        :src="
                          showConfirmPassword
                            ? 'https://cdn-icons-png.flaticon.com/128/10812/10812267.png'
                            : 'https://cdn-icons-png.flaticon.com/128/159/159604.png'
                        "
                        alt="Toggle Password Visibility"
                        width="25"
                        height="25"
                        style="filter: invert(0.4)"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="error !== '' || showError">
              <div class="row" v-if="error != null || showError">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <button
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancelar
              </button>
              <button
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15"
                type="submit"
                :disabled="showError"
                @click.prevent="submitForm"
              >
                {{ title }} Usuario
              </button>
            </div>
          </form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";

export default {
  name: "CreateNewUserModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      showError: false,
      maxLen: 10,
      minLen: 8,
      confirmPasswordError: false,
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      password2: "",
      item: {
        firstName: "",
        lastName: "",
        groups: null,
        username: "",
        phone: "",
        city: "",
      },
      groups: [],
      cities: [],
      currentUser: null,
    };
  },
  watch: {
    async id(newVal) {
      if (newVal > 0) {
        await this.getAllCities();

        await StateStore.actions.actionsGetUser({
          axios: this.axios,
          id: newVal,
        });

        this.item = await StateStore.getters.getUserRetrieve();

        this.item.city = this.item.city?.id || "";
        this.item.groups =
          this.item.groups?.length > 0
            ? this.item.groups[0].id
            : this.item?.compressed?.groups?.length > 0
            ? this.item?.compressed?.groups[0].id
            : null;
      } else if (newVal === undefined) {
        this.item = [];
      }
    },
  },
  mounted() {
    this.currentUser = StateStore.getters.getCurrentUser();
    this.$refs.mymodal.addEventListener(
      "hidden.bs.modal",
      this.handleModalHidden
    );
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener(
      "hidden.bs.modal",
      this.handleModalHidden
    );
  },
  methods: {
    validatePassword() {
      if (this.password !== this.password2) {
        this.confirmPasswordError = true;
        this.error = "Las contraseñas no coinciden";
        this.alert = true;
      } else {
        this.confirmPasswordError = false;
        this.error = "";
        this.alert = false;
      }
    },
    checkMinLength(field, minLen) {
      if (
        field === "phone" &&
        this.item[field] &&
        this.item[field].toString()?.length < minLen
      ) {
        this.error = `El campo debe tener más de ${
          minLen ? minLen : this.minLen
        } caracteres`;
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.error = "";
        }, 5000);
      } else if (
        this.item[field] &&
        this.item[field]?.length < (minLen ? minLen : this.minLen)
      ) {
        this.error = `El campo debe tener más de ${
          minLen ? minLen : this.minLen
        } caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (field === "phone") {
        if (isNaN(this.item[field])) {
          this.showError = true;
          this.error = `Número de Teléfono invalido, rectifiquelo`;
        }
        if (
          this.item &&
          this.item[field] &&
          this.item[field].toString()?.length > maxLen
        ) {
          this.error = `El campo no puede tener más de ${
            maxLen ? maxLen : this.maxLen
          } caracteres`;
          this.showError = true;
        }
        setTimeout(() => {
          this.showError = false;
          this.error = "";
        }, 5000);
      } else if (
        this.item &&
        this.item[field] &&
        this.item[field]?.length >= (maxLen ? maxLen : this.maxLen)
      ) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${
          maxLen ? maxLen : this.maxLen
        } caracteres`;
      } else {
        this.showError = false;
        this.error = "";
      }
    },
    handleModalHidden() {
      this.$emit("modal-closed");
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async getAllGroups() {
      await StateStore.actions.actionsGetAllGroupList({ axios: this.axios });
      this.groups = StateStore.getters.getGroupList();
    },
    async getAllCities() {
      await StateStore.actions.actionsGetAllCityList({ axios: this.axios });
      this.cities = StateStore.getters.getCityList();
    },
    async submitForm() {
      if (this.id === undefined) {
        await StateStore.actions
          .actionsCreateUser({
            axios: this.$axios,
            password: this.password,
            username: this.item.username,
            email: this.item.email,
            firstName: this.item.firstName,
            lastName: this.item.lastName,
            groups: this.item.groups,
            phone: this.item.phone,
            city: this.item.city,
          })
          .then((response) => {
            if (response || stateStore.ok) {
              location.reload();
            } else {
              console.error("Error al crear el usuario:", response);
            }
          })
          .catch((error) => {
            console.error("Error al crear el usuario:", error);
          });
      } else {
        await StateStore.actions
          .actionsEditUser({
            axios: this.$axios,
            id: this.item.id,
            username: this.item.username,
            email: this.item.email,
            firstName: this.item.firstName,
            lastName: this.item.lastName,
            groups: this.item.groups,
            phone: this.item.phone,
            city: this.item.city,
          })
          .then(async (response) => {
            if (response || stateStore.ok) {
              if (this.item.id === this.currentUser.id) {
                await StateStore.actions.actionsMe({ axios: this.$axios });
                await StateStore.actions.actionsCurrentUser({
                  axios: this.$axios,
                });
              }
              location.reload();
            } else {
              console.error("Error al editar el usuario:", response);
            }
          })
          .catch((error) => {
            console.error("Error al editar el usuario:", error);
          });
      }
    },
  },
  created() {
    this.getAllGroups();
    this.getAllCities();
  },
};
</script>
<style scoped>
.form-group {
  position: relative;
}

.form-control {
  padding-right: 40px;
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 10px;
}

.d-block {
  color: #ffffff !important;
}

.has-error input,
.has-error select {
  border-color: red;
}

.password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input .toggle-visibility {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}
</style>
